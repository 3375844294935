
import { Component, Vue, Watch } from 'vue-property-decorator';
import { getNameByEmail } from '@/utils/users';
import accountModule from '@/store/modules/accountModule';
import { getConfigEnv, sleep } from '@/utils/helpers';
import { clearSession, isLoggedIn, refreshAccessToken } from '@/lib/auth';
import GenericMixin from '@/lib/mixins/GenericMixin';
import { mixins } from 'vue-class-component';
import workflow from '../../router/routes/workflow';
import workflowModule from '../../store/modules/workflowModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import WebNotification from '@/utils/web_notificationsv2.js';
import Poller from '@/utils/poller';
import { Debounce } from 'vue-debounce-decorator';
import { DateTime } from 'luxon';
import isOnline from 'is-online';

let AlertsPoller: any;

@Component({
  components: {},
})
export default class NotficationSidebarWidget extends mixins(GenericMixin) {
  webNotification: any = new WebNotification();

  lastNotificationRequestTime: any = new Date().getTime();

  lastNotificationRequestBeforeTime: any = new Date().getTime();

  get isNotificationListOpen() {
    return tasqsListModule.isNotificationListOpen;
  }

  get unReadNotificationCount() {
    return workflowModule.userUnReadNotificationCount;
  }

  get userNotifications() {
    const notifications = workflowModule.userNotifications;
    const formatedNotifications = notifications.map((notification) => {
      let message = `A ${notification.predictionType} Tasq`;
      if (notification.type === 'TASQ_REASSIGNED_TO_ME') {
        message = `${message} was reassigned to you`;
      } else if (notification.type === 'OFF_TARGET_TASQ_ASSIGNED_TO_ME_RETURN_TO_NORMAL') {
        message = `${message} assigned to you returned to target`;
      } else {
        message = `${message} was assigned to you`;
      }

      if (notification.createdBy) {
        message = `${message} by ${getNameByEmail(notification.createdBy)}`;
      }

      return {
        ...notification,
        title: `Tasq - ${notification.nodeName}`,
        time: new Date(notification.time).getTime(),
        message,
      };
    });

    return formatedNotifications;
  }

  toogleNotificationList() {
    tasqsListModule.toggleNotificationList();
  }

  async mounted() {
    this.startAlertsPolling();
    await sleep(1000);
    const hasPermission = await this.checkPermission();
    const loadedSidebarFirstTime = tasqsListModule.isSidebarLoadedFirstTime;
    if (!hasPermission && !loadedSidebarFirstTime) {
      tasqsListModule.setIsSidebarLoadedFirstTime(true);
      this.$tasqAlert({
        title: 'Error',
        message: 'Please enable notifications in your browser settings to stay updated',
        type: 'error',
        duration: 10000,
      });
    }
  }

  async checkPermission() {
    // const webNotification = new WebNotification();

    try {
      const granted = await new Promise((resolve) =>
        this.webNotification.requestPermission((result) => resolve(result))
      );

      if (granted) {
        console.log('Permission Granted.');
        return true;
      } else {
        console.log('Permission Not Granted.');
        return false;
      }
    } catch (error) {
      console.error('Error occurred while checking permission:', error);
    }
  }

  async showNotification(notification) {
    // const webNotification = new WebNotification();
    // console.log(this.webNotification.isEnabled());
    await this.checkPermission();

    await sleep(1000);

    this.webNotification.showNotification(
      notification.title,
      {
        body: notification.message,
        icon: 'https://uploads-ssl.webflow.com/60da89d8bcd8cb14cf908df0/60f1c1343d26400d7fb58a66_Tasq_Webclip.png',
        image: 'https://uploads-ssl.webflow.com/60da89d8bcd8cb14cf908df0/60f1c1343d26400d7fb58a66_Tasq_Webclip.png',

        onClick: () => {
          console.log('Notification clicked.');
          if (!this.isNotificationListOpen) {
            this.toogleNotificationList();
          }
        },
        autoClose: 400000, // auto close the notification after 40 seconds (you can manually close it via hide function)
      },
      async function onShow(error, hide) {
        if (error) {
          console.log('Unable to show notification: ' + error);
        } else {
          console.log('Notification Shown.');

          await new Promise((resolve) => setTimeout(resolve, 20000)); // Wait for 5 seconds

          console.log('Hiding notification....');
          // hide(); // manually close the notification (you can skip this if you use the autoClose option)
        }
      }
    );
  }

  setContent(event) {
    // console.log(event);
  }
  async getUserNotifications() {
    this.lastNotificationRequestTime = this.lastNotificationRequestTime + 1000
    const isAppOnline = await isOnline();
    if (isAppOnline) {
      if (!isLoggedIn()) {
        refreshAccessToken();

        return;
      }

      const lastNotificationRequestTime = DateTime.fromMillis(this.lastNotificationRequestTime);
      const denverTime: any = lastNotificationRequestTime.toUTC().toISO();
      const input: any = this.userNotifications.length ? denverTime.slice(0, -5) : null;
      workflowModule.getUserNotifications(input);
    }
  }

  async startAlertsPolling() {
    if (AlertsPoller) {
      return;
    }

    AlertsPoller = new Poller({ interval: 15000 })
      .onCheck(() => this.getUserNotifications())
      .onEachUpdate(this.setContent)
      .onTimeout(() => AlertsPoller.start())
      .start();
  }

  @Debounce(1000)
  @Watch('userNotifications')
  async onUserNotificationsUpdate(data, oldData) {
    // console.log(data);
    // console.log(oldData);
    if (data && data.length && data.length != oldData.length) {
      console.log(this.lastNotificationRequestTime);
      data.forEach(async (notification) => {
        if (
          !notification.markAsRead &&
          notification.time >= this.lastNotificationRequestTime &&
          !oldData.find((item) => item.notificationID === notification.notificationID)
        ) {
          await this.showNotification(notification);
        }
      });
      // this.lastNotificationRequestTime = new Date().getTime();
    }

  }
}
